
import { defineComponent, ref, watch } from 'vue'
import type { PropType } from 'vue'
import { useStore } from 'vuex'
import useModel from '@/hooks/useModel'
import {
  getRegion as getRegionAPI
} from '@/API/salesReporting/claimCheckingReport'

enum Region {
  North,
  East,
  West,
  South
}

export default defineComponent({
  name: 'RegionSelector',
  props: {
    optionTitle: {
      type: String as PropType<string>,
      default: 'regionEn'
    },
    optionKey: {
      type: String as PropType<string>,
      default: 'id'
    },
    bu: {
      type: String
    },
    value: {
      type: String,
    }
  },
  setup(props) {
    const { state } = useStore()
    const valueModel = useModel(props, 'value')

    const regions = ref([])

    watch(
      () => props.bu,
      async (bu?: string) => {
        regions.value = []
        if (bu) {
          const buId = state.user.buList.find((item: { nameEn: string}) => item.nameEn === bu)?.id
          if (buId !== undefined) {
            regions.value = (await getRegionAPI({ buId })).sort((a: any, b: any) => {
              return Region[a.regionEn] < Region[b.regionEn] ? -1 : 1
            })
          }
        }
        valueModel.value = undefined
      }
    )

    return {
      valueModel,
      regions
    }
  }
})
