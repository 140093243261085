
import { defineComponent } from 'vue';
import useModel from '@/hooks/useModel';
import {
    updatePublishType,
    exportCheckingResultData,
} from '@/API/localRegistration';
import { downloadFromStream } from '@/utils/payment/downloadFile';

export default defineComponent({
    name: 'PublishHistory',
    props: {
        showData: {
            default: [],
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const visibleModal = useModel(props, 'visible');
        const columns = [
            {
                title: 'BU',
                dataIndex: 'bu',
                key: 'bu',
                width: 200,
            },
            {
                title: 'Rt date from',
                dataIndex: 'rtStartTime',
                key: 'rtStartTime',
                width: 200,
            },
            {
                title: 'to',
                dataIndex: 'rtEndTime',
                key: 'rtEndTime',
                width: 200,
            },
            {
                title: 'Calculation rule',
                dataIndex: 'calculationName',
                key: 'calculationName',
                width: 200,
            },
            {
                title: 'Applicable type class',
                dataIndex: 'applicableTypeClass',
                key: 'applicableTypeClass',
                width: 200,
            },
            {
                title: 'VIN no.',
                dataIndex: 'vinNo',
                key: 'vinNo',
                width: 200,
            },
            {
                title: 'Dealer no.',
                dataIndex: 'dealerNo',
                key: 'dealerNo',
                width: 200,
            },
            {
                title: 'Region',
                dataIndex: 'region',
                key: 'region',
                width: 200,
            },
            {
                title: 'Dealer group',
                dataIndex: 'dealerGroup',
                key: 'dealerGroup',
                width: 200,
            },
            {
                title: 'Province_CN',
                dataIndex: 'provinceCn',
                key: 'provinceCn',
                width: 200,
            },
            {
                title: 'Publish code',
                dataIndex: 'publishCode',
                key: 'publishCode',
                width: 200,
            },
            {
                title: 'Operation',
                slots: { customRender: 'Operation' },
            },
        ];

        const handleOk = () => {
            emit('update:visible', false);
        };

        const publish = (record: any, publishType: number) => {
            const params = {
                publishCode: record.publishCode,
                publishType,
            };
            updatePublishType(params).then((_) => {
                record.publishType = publishType;
            });
        };

        const handleExport = async (bu: string, publishCode: string) => {
            const params = {
                bu,
                publishCode,
            };
            const res = await exportCheckingResultData(params);
            downloadFromStream(res);
        };

        return {
            visibleModal,
            columns,
            handleOk,
            publish,
            handleExport,
        };
    },
});
