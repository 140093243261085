import { fetchGet, fetchPost } from '../index';

export const getRegionApi = (param: any): Promise<any> => {
    return fetchPost(`/claimapi/common/regionQuery?bu=${param.bu}`);
};

export const getOfferType = (): Promise<any> => {
    return fetchGet('/pgapi/program/setting/get_offer_type');
};

export const getCustomerType = (): Promise<any> => {
    return fetchGet('/pgapi/program/setting/get_customer_type');
};

export const getDealerGroup = (): Promise<any> => {
    return fetchGet('/dapi/noAuth/dealer/dealerGroupList');
};
