
import { defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import {getDealerGroup} from '@/API/claimDelay/common'
export default defineComponent({
  name: 'DealerGroupSelector',
  props: {
    title: {
      type: String as PropType<string>,
      default: 'name'
    },
    key: {
      type: String as PropType<string>,
      default: 'code'
    }
  },
  setup() {
    const dealerGroups = ref([])
    getDealerGroup().then((res: any) => {
        dealerGroups.value = res;
    });

    return {
      dealerGroups
    }
  }
})
