
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, toRefs, watch, nextTick } from 'vue';
import CalculationRuleSelector from '@/views/LocalRegistration/components/CalculationRuleSelector.vue';
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue';
import ApplicableTypeClassSelector from '@/views/LocalRegistration/components/ApplicableTypeClassSelector.vue';
import RegionSelector from '@/views/LocalRegistration/components/RegionSelector.vue';
import DealerGroupSelector from '@/views/LocalRegistration/components/DealerGroupSelector.vue';
import PublishHistory from '@/views/LocalRegistration/RegistrationResult/PublishHistory.vue';
import {
    getCheckingResultData,
    getCalculationRuleSelection,
    savePublish,
    getPublishHistoryList,
    exportCheckingResultData,
    searchDealerByCodeAndName
} from '@/API/localRegistration';
import { Moment } from 'moment';
import { downloadFromStream } from '@/utils/payment/downloadFile';
interface SearchType {
    applicableTypeClass: string | undefined;
    bu: string | undefined;
    calRuleCode: string | undefined;
    calRuleName: string | undefined;
    dealerCode: string | undefined;
    dealerGroup: string | undefined;
    dealerGroups: string[] | undefined;
    dealerName: string | undefined;
    endTime: Moment | undefined;
    isExemption: string | undefined;
    provinceCn: string | undefined;
    region: string | undefined;
    startTime: Moment | undefined;
    vin: string | undefined;
}

export default defineComponent({
    components: {
        CalculationRuleSelector,
        BuSelector,
        ApplicableTypeClassSelector,
        RegionSelector,
        DealerGroupSelector,
        PublishHistory,
    },
    setup(props) {
        const publishSelectVisible = ref(false);
        const publishType = ref<undefined|1|2>()
        const plainOptions = ['Apple', 'Pear', 'Orange'];
        const historyData = ref<any[]>([]);
        const searchParams = reactive<SearchType>({
            applicableTypeClass: undefined,
            bu: undefined,
            calRuleCode: undefined,
            calRuleName: undefined,
            dealerCode: undefined,
            dealerGroup: undefined,
            dealerGroups: [],
            dealerName: undefined,
            endTime: undefined,
            isExemption: undefined,
            provinceCn: undefined,
            region: undefined,
            startTime: undefined,
            vin: undefined,
        });

        const dealerNameOptions = ref<{value: string}[]>([])

        const getName = (name: string) => {
            searchParams.calRuleName = name;
        };

        const showData = reactive({
            list: [],
            localRegVol: 0,
            lrr1: '0%',
            lrr2: '0%',
            retailVol: 0,
            rtAndRegVol: 0,
        });

        //分页组件
        const pagination = reactive({
            pageSize: 20,
            currentPage: 1,
            total: 0,
        });

        const columns = [
            {
                title: 'BU',
                dataIndex: 'bu',
                key: 'bu',
                width: 200,
            },
            {
                title: 'RT Date',
                dataIndex: 'retailDate',
                key: 'retailDate',
                width: 200,
            },
            {
                title: 'VIN',
                dataIndex: 'vin',
                key: 'vin',
                width: 200,
            },
            {
                title: 'Dealer Code',
                dataIndex: 'dealerCode',
                key: 'dealerCode',
                width: 200,
            },
            {
                title: 'Dealer Name',
                dataIndex: 'dealerNameEn',
                key: 'dealerNameEn',
                width: 500,
            },
            {
                title: 'Model Name',
                dataIndex: 'regModel',
                key: 'regModel',
                width: 500,
            },
            {
                title: 'Vehicle usage',
                dataIndex: 'usage',
                key: 'usage',
                width: 200,
            },
            {
                title: 'Region',
                dataIndex: 'regionNameEn',
                key: 'regionNameEn',
                width: 200,
            },
            {
                title: 'Dealer group',
                dataIndex: 'dealerGroupNameEn',
                key: 'dealerGroupNameEn',
                width: 200,
            },
            {
                title: 'Province',
                dataIndex: 'provinceNameEn',
                key: 'provinceNameEn',
                width: 200,
            },
            {
                title: 'City',
                dataIndex: 'cityNameEn',
                key: 'cityNameEn',
                width: 200,
            },
            {
                title: 'Applicable type class',
                dataIndex: 'applicableTypeClass',
                key: 'applicableTypeClass',
                width: 200,
            },
        ];

        const publishHistoryVisible = ref(false);

        const handlePublishHistory = () => {
            if (searchParams.bu === undefined) {
                message.warning('Please select BU!');
                return;
            }
            getPublishHistoryList({ current: 1, bu: searchParams.bu }).then(
                (res: any) => {
                    historyData.value = res;
                    publishHistoryVisible.value = true;
                }
            );
        };

        const getParams = () => {
            const param = {
                applicableTypeClass: searchParams.applicableTypeClass || '',
                bu: searchParams.bu || '',
                calRuleCode: searchParams.calRuleCode || '',
                calRuleName: searchParams.calRuleName || '',
                dealerCode: searchParams.dealerCode || '',
                dealerGroups: searchParams.dealerGroups,
                dealerName: searchParams.dealerName || '',
                endTime: searchParams.endTime
                    ? searchParams.endTime.format('yyyy/MM/DD')
                    : '',
                isExemption: searchParams.isExemption || '',
                provinceCn: searchParams.provinceCn || '',
                region: searchParams.region || '',
                startTime: searchParams.startTime
                    ? searchParams.startTime.format('yyyy/MM/DD')
                    : '',
                vin: searchParams.vin || '',
            };

            return param;
        };

        const getParams2 = () => {
            const param = {
                applicableTypeClass:
                    searchParams.applicableTypeClass || undefined,
                bu: searchParams.bu || undefined,
                calRuleCode: searchParams.calRuleCode || undefined,
                calRuleName: searchParams.calRuleName || undefined,
                dealerCode: searchParams.dealerCode || undefined,
                dealerGroups:
                    searchParams.dealerGroups?.length === 0
                        ? undefined
                        : searchParams.dealerGroups,
                dealerName: searchParams.dealerName || undefined,
                endTime: searchParams.endTime
                    ? searchParams.endTime.format('yyyy/MM/DD')
                    : undefined,
                isExemption: searchParams.isExemption || undefined,
                provinceCn: searchParams.provinceCn || undefined,
                region: searchParams.region || undefined,
                startTime: searchParams.startTime
                    ? searchParams.startTime.format('yyyy/MM/DD')
                    : undefined,
                vin: searchParams.vin || undefined,
            };

            return param;
        };
        const resetSearchData = () => {
            Object.assign(searchParams, {
                applicableTypeClass: undefined,
                bu: undefined,
                calRuleCode: undefined,
                calRuleName: undefined,
                dealerCode: undefined,
                dealerGroups: [],
                dealerName: undefined,
                endTime: undefined,
                isExemption: undefined,
                provinceCn: undefined,
                region: undefined,
                startTime: undefined,
                vin: undefined,
            });
        };

        const mustSelectConditions = () => {
            if (
                searchParams.bu === undefined ||
                searchParams.calRuleCode === undefined
            ) {
                message.warning('Please select BU and Calcualtion Rule!');
                return true;
            }
            return false;
        };

        const publish = () => {
            if (publishType.value) {
                savePublish(
                    Object.assign({ publishType: publishType.value }, getParams())
                ).then((res) => {
                    publishSelectVisible.value = false;
                    publishType.value = undefined;
                });
            } else {
                message.error('Please select one');
            }
        };

        const handleExport = async () => {
            const res = await exportCheckingResultData(getParams());
            downloadFromStream(res);
        };

        // 刷新表格高度
        const tableHeight = ref('0');
        const getTableScroll = () => {
            nextTick(() => {
                //  默认底部分页50 + 边距10
                const extraHeight = 150;
                const tHeader = document.getElementsByClassName(
                    'content-container'
                )[0];
                //表格内容距离顶部的距离
                let tHeaderBottom = 0;
                if (tHeader) {
                    tHeaderBottom = tHeader.getBoundingClientRect().top;
                }
                //窗体高度-表格内容顶部的高度-表格内容底部的高度
                const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
                tableHeight.value = height;
            });
        };

        const searchData = () => {
            if (mustSelectConditions()) return;
            getCheckingResultData(getParams2(), {
                current: pagination.currentPage,
                size: pagination.pageSize,
            }).then((res: any) => {
                showData.list = res.page.content;
                showData.localRegVol = res.localRegVol;
                showData.retailVol = res.retailVol;
                showData.rtAndRegVol = res.rtAndRegVol;
                showData.lrr1 = res.lrr1 || '0%';
                showData.lrr2 = res.lrr2 || '0%';
                pagination.total = res.page.totalElements;
            });
            getTableScroll();
        };

        const pageChange = (page: number) => {
            pagination.currentPage = page > 0 ? page : 0;
            searchData();
        };
        const sizeChange = (page: number, pageSize: number) => {
            pagination.currentPage = page > 0 ? page : 0;
            pagination.pageSize = pageSize;
            searchData();
        };
        const handleSearch = () => {
          pagination.currentPage = 1
          searchData();
        }

        const handleSearchDealerName = async (searchText: string) => {
          dealerNameOptions.value = (await searchDealerByCodeAndName({
            dealerNameCn: searchText
          }) as any).data.map((item: any) => {
            return {
              value: item.dealerInfo.dealerNameCn
            }
          })
        }

        return {
            historyData,
            plainOptions,
            publishType,
            publish,
            publishSelectVisible,
            resetSearchData,
            ...toRefs(showData),
            searchData,
            ...toRefs(searchParams),
            columns,
            publishHistoryVisible,
            handlePublishHistory,
            handleExport,
            pagination,
            tableHeight,
            pageChange,
            sizeChange,
            handleSearch,
            getName,
            dealerNameOptions,
            handleSearchDealerName
        };
    },
});
