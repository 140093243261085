import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "operation" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    class: "registrationg-result-checking-result-modal",
    title: "Publish history",
    width: "80%",
    "cancel-button-props": { style: { display: 'none' } },
    "ok-text": "Cancel",
    visible: _ctx.visibleModal,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visibleModal) = $event)),
    onOk: _ctx.handleOk
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        class: "data-table",
        dataSource: _ctx.showData,
        scroll: { x: 2500 },
        pagination: false,
        columns: _ctx.columns
      }, {
        Operation: _withCtx(({record}) => [
          _createElementVNode("div", _hoisted_1, [
            (record.publishType === 1)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.publish(record, 2))
                }, "Publish to External", 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  onClick: ($event: any) => (_ctx.publish(record, 1))
                }, "Publish to Internal", 8, _hoisted_3)),
            _createElementVNode("span", {
              onClick: ($event: any) => (_ctx.handleExport(record.bu, record.publishCode))
            }, "Export", 8, _hoisted_4)
          ])
        ]),
        _: 1
      }, 8, ["dataSource", "columns"])
    ]),
    _: 1
  }, 8, ["visible", "onOk"]))
}